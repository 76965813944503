import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Modal, Button, Select, Drawer, Checkbox, Input, Skeleton, message } from "antd";
import { CloseOutlined, CloseSquareFilled } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { Scrollbars } from "react-custom-scrollbars";
import DefaultProductImage from "../../../assets/images/no-image.svg";
import "../../style.css";
import "./styles.css";
import db from "../../../database";
import { debounce } from "lodash";
import data from "../../../constants/retailPos.json";
import i18next from "i18next";
import locale from "antd/lib/date-picker/locale/en_US";
import tick from "../../../assets/images/tic.png";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import MoreOptionsIcon from "../../../assets/images/moreOptionsIcon.svg";
import filterList from "../../../assets/images/filterList.svg";
import gridFilterInActive from "../../../assets/images/grid_filter2.svg";
import gridFilter from "../../../assets/images/grid_filter1.svg";

const ProductPanelSM = (props) => {
  const {
    checkIsManualWeight,
    isProductsFilter,
    getMoreProducts,
    productListCardRef,
    selectProductInCart,
    productItems,
    setProductItems,
    productsDisplay,
    setIsProductsVisible,
    isProductsVisible,
    pickProduct,
    selectProductToCart,
    filterDrawer,
    setFilterDrawer,
    productSearchInput,
    setProductSearchInput,
    setOpen,
    productSearchInputRef,
    setKeyboardType,
    clearProductSearchResults,
    productsData,
  } = props;
  const [displayBatchSelection, setDisplayBatchSelection] = useState(false);
  const [batchSetAvailable, setBatchSetAvailable] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const [selectedProductBrand, setSelectedProductBrand] = useState([]);
  const [selectCategotyList, setSelectCategotyList] = useState([]);
  const [loading, setLoading] = useState(true);

  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const { Option } = Select;
  const t = i18next.t;
  
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await db.productCategories.toArray().then((res) => {
        // Map the label and value
        const mappedCategories = res.map((item) => ({
          ...item,
          label: item.value,
          value: item.mProductCategoryId,
        }));

        // Sort alphabetically by label
        const sortedCategories = mappedCategories.sort((a, b) => a.label.localeCompare(b.label));

        // Set the sorted data
        setCategoriesData(sortedCategories);
      });

      await db.productBrands.toArray().then((res) => {
        // Map the label and value
        const mappedBrands = res.map((item) => ({
          ...item,
          label: item.name,
          value: item.brandId,
        }));

        // Sort alphabetically by label
        const sortedBrands = mappedBrands.sort((a, b) => a.label.localeCompare(b.label));

        // Set the sorted data
        setBrandsData(sortedBrands);
      });

 
      await db.products.toArray().then((productsFetched) => {
         setProductsList([...productsFetched.slice(0, 50)]);
       });
       setLoading(false); 
     };
     filterDrawer ? fetchData() : onFilterClose();
  }, [filterDrawer]);

  const filterProducts = async (brands, categories) => {
    try {
      let productsData = await db.products.toArray();

      if (brands.length > 0 || categories.length > 0) {
        productsData = productsData.filter(
          (item) =>
            (brands.length === 0 || brands.includes(item.brandId)) &&
            (categories.length === 0 || categories.includes(item.mProductCategoryId))
        );
      }

      setProductsList(productsData.slice(0, 50));
    } catch (error) {
      console.error("Error filtering products:", error);
    }
  };

  const debouncedBrandChange = debounce((brandId) => {
    const isSelected = selectedProductBrand?.includes(brandId);
    let updatedSelectedBrands = [];

    if (isSelected) {
      updatedSelectedBrands = selectedProductBrand?.filter((id) => id !== brandId);
    } else {
      let tempBrand = selectedProductBrand || [];
      updatedSelectedBrands = [...tempBrand, brandId];
    }

    setSelectedProductBrand(updatedSelectedBrands || []);

    filterProducts(updatedSelectedBrands || [], selectCategotyList);
  }, 300);

  const handleBrandCheckboxChange = (brandId) => {
    debouncedBrandChange(brandId);
  };

  const debouncedCategoryChange = debounce((checkedValues) => {
    setSelectCategotyList(checkedValues);
    filterProducts(selectedProductBrand, checkedValues);
  }, 300);

  const handleCategoryCheckboxChange = (checkedValues) => {
    debouncedCategoryChange(checkedValues);
  };

  const handleSelectProduct = async() => {
    await db.products.toArray().then((productsFetched) => {
      const lowerCaseSearchInput = productSearchInput.toLowerCase();
      const filteredProducts = productsFetched.filter(
        (product) =>
          product?.name?.toLowerCase().includes(lowerCaseSearchInput) ||
          product?.batchIndex === lowerCaseSearchInput ||
          product?.upcIndex === lowerCaseSearchInput ||
          product?.value === lowerCaseSearchInput ||
          product?.upc === lowerCaseSearchInput
      );
      if (filteredProducts.length === 0) {
        {
          message.info(`${t("product_search_category_error")}`);
        }
      } else {
        setProductsList(filteredProducts)
      }
    });
  };

  const onFilterClose = () => {
    setCategoriesData([]);
    setProductsList([]);
    setBrandsData([]);
    setSelectCategotyList([]);
    setSelectedProductBrand([]);
    setProductSearchInput("");
    setFilterDrawer(false);
  };

  return (
    <React.Fragment>
      {isProductsVisible ? <Scrollbars className="productScroll1">
        {productsData.map((obj, i) => (
          <Row gutter={[15, 11]} style={{ width: "99%", backgroundColor: "#fff", marginTop: "1.5vh", height: "11vh", marginLeft: "-0.1vw", borderRadius: "10px" }}>
            <Col span={4}>
              <img
                style={{
                  width: "100%",
                  height: "10vh",
                  objectFit: "contain",
                  borderRadius: "5px",
                  paddingTop: "1vh",
                  alignSelf: "center",
                }}
                src={obj.imageurl || DefaultProductImage}
                alt=""
              />
            </Col>
            <Col span={16}>
              <div style={{ maxWidth: "100%", overflow: "hidden" }}>
                <label style={{ ...data.posScreenStyles.productsCard.cardDetails.productDescr, whiteSpace: "nowrap", textOverflow: "ellipsis" }} className="productName">
                  {obj["description"]}
                </label>
              </div>
              <p style={data.posScreenStyles.productsCard.cardDetails.superMarketProd} className="productName">
                {obj["name"]}
                <span style={{ display: "block" }}>{obj["value"]}</span>
              </p>
            </Col>
            <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <span className="productDescription-kiosk" title={obj["description"]}>
                {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                {obj.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}/{obj["uomName"]}
              </span>
              <span onClick={() => { pickProduct(obj) }} style={{ fontSize: "1.5vh", color: "#fff", backgroundColor: "#2F3856", padding: "1vh 1.7vw", marginTop: "2%", borderRadius: "3px", cursor: "pointer" }}>ADD</span>
            </Col>
            <span tabIndex={0}></span>
          </Row>
        ))}
      </Scrollbars> : null}
      <Drawer
        placement="bottom"
        closable={false}
        maskClosable={false}
        className="filter"
        bodyStyle={{ paddingBottom: 0 }}
        onClose={onFilterClose}
        id="sm-drawer-close"
        height="88vh"
        visible={filterDrawer}
      >

          <Row style={{ padding: "0", height: "83vh" }}>
            <Col span={4}>
              <div style={{ paddingBottom: "2vh" }}>
              <Skeleton active loading={loading} paragraph={{ rows: 6 }}>
                <div
                  style={{
                    height: "5vh",
                    backgroundColor: "rgba(146, 144, 152, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "1vw",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <span>Shop By Category</span>
                </div>
                <div style={{ backgroundColor: "#fff", height: "33vh", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                  <Scrollbars className="productCategorySuper">
                    <Checkbox.Group
                      style={{ padding: "1.5vh 1.5vw 0 1.5vw" }}
                      value={selectCategotyList}
                      options={categoriesData.map((category) => ({ label: category.name, value: category.mProductCategoryId }))}
                      onChange={handleCategoryCheckboxChange}
                    />
                  </Scrollbars>
                </div>
                </Skeleton>
              </div>
              <div>
              <Skeleton active loading={loading} paragraph={{ rows: 6 }}>
                <div
                  style={{
                    height: "5vh",
                    backgroundColor: "rgba(146, 144, 152, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "1vw",
                    borderTopLeftRadius: "5px",
                    borderTopRightRadius: "5px",
                  }}
                >
                  <span>Brands</span>
                </div>
                <div style={{ backgroundColor: "#fff", height: "33vh", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px" }}>
                  <Scrollbars className="productCategorySuper">
                    {brandsData.map((item, i) => {
                      return (
                        <div style={{ padding: "1.5vh 1.5vw 0 1.5vw" }}>
                          <Checkbox
                            id={`sm-checkbox-${item.name}`}
                            checked={selectedProductBrand?.includes(item.brandId)}
                            onChange={() => handleBrandCheckboxChange(item.brandId)}
                          />
                          <span
                            key={i}
                            onClick={() => {
                              setSelectedProductBrand(item.brandId);
                            }}
                            style={{
                              whiteSpace: "nowrap",
                              alignSelf: "center",
                              padding: "3px 15px",
                              backgroundColor: item.selected === "Y" ? "#2F3856" : "#fff",
                              borderRadius: "7px",
                              color: item.selected === "Y" ? "#fff" : "#000",
                              cursor: "pointer",
                            }}
                          >
                            {item.name}
                          </span>
                        </div>
                      );
                    })}
                  </Scrollbars>
                </div>
</Skeleton>
              </div>
            </Col>
            <Col span={19} offset={1}>
              {
                loading ? (
                  <>
                    {[...Array(10)].map((_, index) => (
                      <React.Fragment key={index}>
                        <Skeleton.Button block />
                        <br />
                        <br />
                      </React.Fragment>
                    ))}
                  </>
                ): (
                  <>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <p style={{ margin: 0, fontSize: "4vh", position: "relative", top: "-1vh", fontWeight: 600 }}>Best Sellers</p>
                <CloseOutlined onClick={onFilterClose} style={{ fontSize: "1.5vw", marginTop: "-2vh", cursor: "pointer" }} />
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "75%" }}>
                  <Input
                    placeholder="Search for products by code/name"
                    ref={productSearchInputRef}
                    id="sm-product-search"
                    onChange={(e) => {
                      e.target.value === "" ? clearProductSearchResults() : setProductSearchInput(e.target.value);
                    }}
                    className="productSearch-sm"
                    style={{ borderRadius: "7px", padding: "0.8vh 0.8vw", borderColor: "#fff" }}
                    value={productSearchInput}
                    onFocus={() => {
                      setKeyboardType({ product: true, parkedBill: false, salesHistory: false, salesHistoryCus: false });
                      setOpen(true);
                      productSearchInputRef.current.focus();
                    }}
                    onPressEnter={handleSelectProduct}
                    suffix={
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {productSearchInput?.length > 0 ? (
                          <CloseSquareFilled
                            style={{ fontSize: "20px", color: "#2F3856" }}
                            onClick={async() => {
                              setProductSearchInput("");
                              await db.products.toArray().then((productsFetched) => {
                                setProductsList([...productsFetched.slice(0, 50)]);
                              });
                            }}
                          />
                        ) : null}
                      </div>
                    }
                    prefix={<img src={SearchIcon} alt="" onClick={handleSelectProduct} style={{cursor:"pointer"}}/>}
                  />
                </div>
                <Select
                  className="selecItem"
                  placeholder={<span style={{ paddingTop: "2em" }} className="cartSelect">Sort By Latest</span>}
                  suffixIcon={<img src={MoreOptionsIcon} alt="" style={{ height: "1vh", marginBottom: "15%" }} />}
                  style={{ marginLeft: "1vw" }}
                >
                  <Option className="cartOption" key="discount" value="discount">
                    Price - low to high
                  </Option>
                  <Option className="cartOption" key="parkBill" value="parkBill">
                    Price - high to low
                  </Option>
                  <Option className="cartOption" key="clearCart" value="clearCart">
                    By Popular
                  </Option>
                  <Option className="cartOption" key="clearCart" value="clearCart">
                    By Popular
                  </Option>
                  <Option className="cartOption" key="clearCart" value="clearCart">
                    % off - high to low
                  </Option>
                </Select>
                <Button.Group style={{ width: "8%", height: "5vh", marginLeft: "0.6vw" }}>
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "3vw",
                      borderTopLeftRadius: "5px",
                      borderBottomLeftRadius: "5px",
                      margin: "0 0 0 0.5vw",
                      height: "inherit",
                      borderColor: "#fff",
                      backgroundColor: productsDisplay === "super-market" ? "#2F3856" : "",
                    }}
                  >
                    {" "}
                    <img src={filterList} style={{ height: "2.3vh" }} />
                  </Button>
                  <Button
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "3vw",
                      borderTopRightRadius: "5px",
                      borderBottomRightRadius: "5px",
                      height: "inherit",
                      backgroundColor: productsDisplay === "superMarket" ? "#2F3856" : "",
                    }}
                  >
                    <img src={productsDisplay === "super-market" ? gridFilterInActive : gridFilter} style={{ height: "2.3vh" }} />
                  </Button>
                </Button.Group>
              </div>
              <Scrollbars className="productScroll1">
                {productsList.map((obj, i) => (
                  <Row gutter={[15, 11]} style={{ width: "99%", backgroundColor: "#fff", marginTop: "1.5vh", height: "11vh", marginLeft: "-0.1vw", borderRadius: "10px" }}>
                    <Col span={4}>
                      <img
                        style={{
                          width: "100%",
                          height: "10vh",
                          objectFit: "contain",
                          borderRadius: "5px",
                          paddingTop: "1vh",
                          alignSelf: "center",
                        }}
                        src={obj.imageurl || DefaultProductImage}
                        alt=""
                      />
                    </Col>
                    <Col span={16}>
                      <div style={{ maxWidth: "100%", overflow: "hidden" }}>
                        <label style={{ ...data.posScreenStyles.productsCard.cardDetails.productDescr, whiteSpace: "nowrap", textOverflow: "ellipsis" }} className="productName">
                          {obj["description"]}
                        </label>
                      </div>
                      <p style={data.posScreenStyles.productsCard.cardDetails.superMarketProd} className="productName">
                        {obj["name"]}
                        <span style={{ display: "block" }}>{obj["value"]}</span>
                      </p>
                    </Col>
                    <Col span={4} style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                      <span className="productDescription-kiosk" title={obj["description"]}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                        {obj.sunitprice} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}/{obj["uomName"]}
                      </span>
                      <span onClick={() => { pickProduct(obj) }} style={{ fontSize: "1.5vh", color: "#fff", backgroundColor: "#2F3856", padding: "1vh 1.7vw", marginTop: "2%", borderRadius: "3px", cursor: "pointer" }}>ADD</span>
                    </Col>
                    <span tabIndex={0}></span>
                  </Row>
                ))}
              </Scrollbars>
              </>
                )
              }

              
            </Col>
          </Row>
      </Drawer>

      {/* BATCH SELECTION MODAL START*/}
      <Modal visible={displayBatchSelection} keyboard={false} closable={false} footer={null} width="35%" bodyStyle={{ padding: "20px 15px 15px" }}>
        <Row>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.title}>Product Batch</p>
          </Col>
          <Col span={12}>
            <p style={data.posScreenStyles.offerSelectionModal.closeIconP}>
              <CloseOutlined onClick={() => setDisplayBatchSelection(false)} style={data.posScreenStyles.offerSelectionModal.closeIcon} />
            </p>
          </Col>
        </Row>
        <Row>
          <Card className="orderHistoryCard" style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
            <Scrollbars className="orderHistoryScroll">
              {batchSetAvailable.map((item) => (
                <Card
                  style={data.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                  bodyStyle={{ padding: 10 }}
                  onClick={() => selectProductToCart(item)}
                  onKeyPress={(e) => (e.charCode === 13 ? selectProductToCart(item) : null)}
                  className="focusDashboardCard"
                  tabIndex={0}
                >
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderDate}>Batch</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Mrp</p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderCustomerName}>Price</p>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderNum}>{item.batchno}</p>
                    </Col>
                    <Col span={12}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>
                        {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} {parseFloat(item.mrpPrice).toFixed(2)}{" "}
                        {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}
                      </p>
                    </Col>
                    {/* <Col span={8}>
                      <p style={data.posScreenStyles.orderHistoryModal.orderStatus}>{currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} {parseFloat(item.salePrice).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</p>
                    </Col> */}
                  </Row>
                </Card>
              ))}
              {/* <span tabIndex={0}></span> */}
            </Scrollbars>
          </Card>
        </Row>
      </Modal>
      {/* BATCH SELECTION MODAL END*/}
    </React.Fragment>
  );
};

export default ProductPanelSM;
