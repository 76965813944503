import db from "../../../../database";
import Axios from "axios";
import moment from "moment";
import { getOAuthHeaders } from "../../../../constants/oAuthValidation";

const SyncData = async (item, type) => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const authHeaders = getOAuthHeaders();
  let token;
  if (authHeaders){
    token = authHeaders.access_token;
  }
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  // let array = await db.fbOrderData.toArray()
  console.log(item,"array-------------array")
  if (type === "upsertTableStatus") {
    const paramsInput = {
      query: `mutation {
                  upsertTableStatus(tableStatus: {
                     cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
                    fbtableStatusId: "${item.fbtableStatusId}"
                    fbTableId: "${item.cwrFbTableId}"
                    fbSectionId: "${item.cwrFbsectionId}"
                    status: "${item.statusType}"
                    guests: ${item.noOfPersons}
                    guestName: "${item.guestName}"
                    guestType: "${item.guestType}"
                    referredBy: "${item.referredBy}"
                    salesRepId: "${item.waiter}"
                   }) { 
                status
                message   
                }
                }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${token}`,
      },
    }).then(async (response) => {
      if (response.data.data.upsertTableStatus.status === "200") {
        let allTableData = await db.tableData.toArray();
        let data = [];
        allTableData.map((ele) => {
          if(item.cwrFbTableId === ele.cwrFbTableId && item.statusType === "OPN"){
            ele.ordered = "Y"
            ele.statusType = "OPN";
            ele.color = "#a7c957";
            ele.tabbleSync = "Y";
          }
          data.push(ele);
        });
        await db.tableData.clear();
        await db.tableData.bulkPut(data); 
        // await db.tableData.put(item, item.cwrFbTableId);
      }
    });
  } else if (type === "cancelItem") {
    const cancelItemsData = {
      query: `mutation {
                cancelFBOrder(fbOrder: {
                  fbOrderId:"${item.fbOrderId}"
                  lines:[${item.cancelItems}]
                 }) { 
              status
              message   
              }
              }`,
    };
    await Axios({
      url: serverUrl,
      method: "POST",
      data: cancelItemsData,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${token}`,
      },
    });
  } else if (type === "reservation") {
    const paramsInput = {
      query: `mutation {
              upsertTableReservation(tableReservation: {
                cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
                fbTableReservationId: "${item.uniqueId}"
                fbTableId: "${item.cwrFbTableId}"
                fbSectionId: "${item.cwrFbsectionId}"
                customerName: "${item.name === undefined ? "" : item.name}"
                noOfPeople: "${item.noOfPersons}"
                contactNo: "${item.mobile === undefined ? "" : item.mobile}"
                guestType: "${item.guestType}"
                referredBy: "${item.referredBy === undefined ? "" : item.referredBy}"
                resDate: "${moment(item.time).format("YYYY-MM-DD")}"
                resTime: "${moment(item.time).format("YYYY-MM-DD HH:mm:ss")}"
               }) { 
            status
            message   
            }
            }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${token}`,
      },
    });
  } else if (type === "upsertFbOrder") {
    db.fbOrderData
      .where("fbOrderSync")
      .equals("N")
      .toArray()
      .then((order) => {
        if (order.length > 0) {
          for (let i = 0; i < order.length; i += 1) {
            const paramsInput = {
              query: `mutation {
                          upsertFBOrder(order: {
                            cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}"
                            fbOrderId: "${order[i].fbOrderId}"
                            fbTableId: "${order[i]?.cwrFbTableId}"
                            fbSectionId: "${order[i]?.cwrFbsectionId}"
                            sOrderId: null
                            isCanceled: "N"
                            orderNo: "${localStorage.getItem("orderId") === null ? 1 : localStorage.getItem("orderId")}"
                            guestName: ${order[i]?.guestName === undefined ? null : `"${order[i].guestName}"`}
                            guestType: ${order[i]?.guestType === undefined ? null : `"${order[i].guestType}"`}
                            referredBy: ${order[i]?.referredBy === undefined ? null : `"${order[i].referredBy}"`}
                            date: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                            status: "${order[i].fbOrderStatus}"
                            salesRepId: ${order[i]?.waiter === null ? null : `"${order[i]?.waiter}"`}
                            guests: ${order[i]?.noOfPersons}
                            lines: [${order[i].lines}]
                           }) { 
                        status
                        message   
                        }
                        }`,
            };
            Axios({
              url: serverUrl,
              method: "POST",
              data: paramsInput,
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${token}`,
              },
            }).then(async(response) => {
              db.fbOrderData.where("fbOrderId").equals(order[i].fbOrderId).modify({ fbOrderSync : "Y" });
            });
          }
        }
      });
  }
};

export { SyncData };
