import { pricingRuleController } from "./PricingRules/pricingRuleController";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import { getOAuthHeaders } from "../../../constants/oAuthValidation";
import PoleDisplay from "../../../lib/printer/poleDisplay";
import db from "../../../database";
import moment from "moment";

export const addProductToCart = async (
  addToCart,
  qty,
  totalQtyFlag,
  cart,
  setCart,
  setSelectedProductInCart,
  deleteCart,
  processTotalManualDiscount,
  setLoader,
  salesRepresentDefaultLine,
  tillData,
  cartRef,
  productsCopy,
  salesRepresent,
  orderType,
  processBillDiscounts,
  modifiedPrice,
  setSelectedRowKeys,
  setOrderTimeDetails
) => {
  const weight = parseFloat(qty);
  const addedToCart = cart.items;
  let expiryDiscount = localStorage.getItem("expiryDiscount") !== null && localStorage.getItem("expiryDiscount") === "Y" ? true : false;
  let index = addedToCart.findIndex(
    (p) => p.productId === addToCart.productId && p.upc === addToCart.upc && p.mBatchId === addToCart.mBatchId && p.isReturn === false && !expiryDiscount && !p.discountType
  );
  let weightFlag = false;

  if (expiryDiscount === true) {
    index = addedToCart.findIndex(
      (p) =>
        p.productId === addToCart.productId &&
        p.upc === addToCart.upc &&
        p.mBatchId === addToCart.mBatchId &&
        p.isReturn === false &&
        expiryDiscount &&
        p.lineId === addToCart.lineId
    );
  }

  if (addToCart.isGiftCard === true) {
    index = addedToCart.findIndex((p) => p.productId === addToCart.productId && p.upc === addToCart.upc && p.lineId === addToCart.lineId);
  }

  if (addToCart.discountType) {
    index = addedToCart.findIndex(
      (p) =>
        p.productId === addToCart.productId &&
        p.upc === addToCart.upc &&
        p.mBatchId === addToCart.mBatchId &&
        p.isReturn === false &&
        !expiryDiscount &&
        p.lineId === addToCart.lineId
    );
  }

  if (addToCart.discount <= 0) {
    delete addToCart.priority;
  }

  if (index >= 0 && !addedToCart?.[index]?.parkedItem && !addedToCart?.[index]?.discountType) {
    let couponCode = (addedToCart[index].weight = totalQtyFlag
      ? parseFloat(weight.toFixed(addToCart.isQtyDesimal))
      : parseFloat(addedToCart[index].weight.toFixed(addToCart.isQtyDesimal)) + parseFloat(parseFloat(weight).toFixed(addToCart.isQtyDesimal)));
    addToCart.weight = parseFloat(addedToCart[index].weight.toFixed(addToCart.isQtyDesimal));

    if (parseFloat(addedToCart[index].weight) <= 0) {
      weightFlag = true;
      setSelectedProductInCart({});
      let setAuthTokens;
      const authHeaders = await getOAuthHeaders();
      if (authHeaders && authHeaders.access_token) {
        setAuthTokens = authHeaders.access_token;
      }
      const serverUrl = process.env.REACT_APP_serverUrl;

      if (addedToCart[index].iscoupon) {
        const discardCoupon = {
          query: `query{
              discardCoupon(couponcode:"${addedToCart[index].couponInput}",referenceId: "${addedToCart[index].referenceId}"){
                  status
                  message
              }
          }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: discardCoupon,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        });
      }
      if(cart.couponInput?.length>0){
        cart.couponInput = cart.couponInput?.filter((coupon) => {
          if (addedToCart[index].couponInput !== coupon) {
            return coupon;
          }
        });
      }

      if (addToCart.isGiftCard) {
        let giftCardDataIndex = cart.giftCardData.findIndex((giftCard) => giftCard.number === addToCart.cardNo);
        const discardGiftCard = {
          query: `mutation{
                    discardGiftCard(giftCards: [{cardNo: "${cart.giftCardData[giftCardDataIndex].number}" , referenceNo : "${cart.giftCardData[giftCardDataIndex].refId}"}]){
                      status
                      message
              }
          }`,
        };
        Axios({
          url: serverUrl,
          method: "POST",
          data: discardGiftCard,
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        }).then((res) => {
          cart.giftCardData.splice(giftCardDataIndex, 1);
        });
      }
      message.warning(`${addToCart.name} Removed Successfully`, 0.1);
      addedToCart.splice(index, 1);
    } else {
      let manualDiscountInput = 0;
      if (addedToCart[index].discountType === "PD") {
        manualDiscountInput = addedToCart[index].discountValue;
      }
      const discountAmt = (parseFloat(manualDiscountInput) / 100) * parseFloat(addedToCart[index].realPrice);
      addedToCart[index].discount = totalQtyFlag ? discountAmt * weight : discountAmt * addedToCart[index].weight;
      const sp = parseFloat(addedToCart[index].realPrice) - discountAmt;
      const mrp = totalQtyFlag ? parseFloat(sp) * weight : parseFloat(sp) * addedToCart[index].weight;
      const tax = mrp - mrp / (1 + addedToCart[index].taxRate / 100);
      addedToCart[index].taxAmount = parseFloat(tax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
      addedToCart[index].nettotal = parseFloat(mrp.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
      delete addedToCart[index].nextRule;
      if (addedToCart[index].discount <= 0) {
        delete addedToCart[index].priority;
      }
      message.success(`${addToCart.name} ${qty < 0 ? "Removed Successfully" : "Added Successfully"}`, 0.1);
    }
  } else if (index >= 0 && !addedToCart?.[index]?.parkedItem && addedToCart?.[index]?.discountType) {
    addedToCart[index].weight = totalQtyFlag
      ? parseFloat(weight.toFixed(addToCart.isQtyDesimal))
      : parseFloat(addedToCart[index].weight.toFixed(addToCart.isQtyDesimal)) + parseFloat(parseFloat(weight).toFixed(addToCart.isQtyDesimal));
    addToCart.weight = parseFloat(addedToCart[index].weight.toFixed(addToCart.isQtyDesimal));
    if (parseFloat(addedToCart[index].weight) === 0) {
      setSelectedProductInCart({});
      message.warning(`${addToCart.name} Removed Successfully`, 0.1);
      addedToCart.splice(index, 1);
    }
  } else {
    if (parseFloat(weight) !== 0 && (!('isBlindReceipt' in cart) || (cart.isBlindReceipt && addToCart.isGiftCard))) {
      addToCart.weight = parseFloat(weight.toFixed(addToCart.isQtyDesimal));
      delete addToCart.nextRule;
      const mrp = modifiedPrice ? modifiedPrice : parseFloat(addToCart.salePrice) * parseFloat(weight);
      const tax = mrp - mrp / (1 + addToCart.taxRate / 100);
      let netStd = parseFloat(addToCart.sunitprice - addToCart.sunitprice / (1 + addToCart.taxRate / 100));
      addToCart.taxAmount = tax;
      addToCart.nettotal = parseFloat((mrp - parseFloat(addToCart.discount ? addToCart.discount : 0)).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
      addToCart.unitTax = addToCart?.isReturn
        ? addToCart.unitTax
        : parseFloat((addToCart.salePrice - addToCart.salePrice / (1 + addToCart.taxRate / 100)).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
      addToCart.netStd = addToCart.sunitprice > 0 ? parseFloat((addToCart.sunitprice - netStd).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) : 0;
      addToCart.lineId = uuidv4().replace(/-/g, "").toUpperCase();
      addToCart.salesRepId = salesRepresentDefaultLine.salesRepresentId;
      addToCart.salesRepName = Object.keys(salesRepresent).length > 0 ? salesRepresentDefaultLine.name : "";
      addedToCart.unshift(addToCart);
      let latestIndex = 0; // Since we used unshift, the latest item is at index 0
      setSelectedRowKeys([latestIndex]);
      setSelectedProductInCart(addToCart);
      message.success(`${addToCart.name} ${qty < 0 ? "Removed Successfully" : "Added Successfully"}`, 0.1);
      PoleDisplay(addToCart, "Add item to cart");
    }
  }

  let totalTax = 0;
  let totalPrice = 0;
  let totalItemsQty = 0;
  let totalDiscounts = 0;
  for (let i = 0; i < addedToCart.length; i += 1) {
    totalPrice += parseFloat(addedToCart[i].nettotal.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    totalItemsQty += addedToCart[i].weight;
    totalTax += parseFloat(addedToCart[i].taxAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    totalDiscounts += parseFloat(addedToCart[i].discount?.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    addedToCart[i].key = i;
  }

  const roundOffValue = Math.round(totalPrice);
  const totalRoundOff = totalPrice - roundOffValue;
  if (cart.items.length === 0) {
    deleteCart();
  }
  let cartObj = {
    ...cart,
    items: [...addedToCart],
    total: parseFloat(totalPrice.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
    tax: totalTax,
    discount: totalDiscounts,
    totalQty: totalItemsQty,
    roundOff: totalRoundOff,
  };

  if (cartObj.items.length === 0 && tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId === cart.customer.cwrCustomerId && cartObj.description !== "") {
    let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails")) ? JSON.parse(localStorage.getItem("orderTimeDetails")) : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
    orderTimeDetails = {
      ...orderTimeDetails,
      orderStartTime: "", // Update orderStartTime to current time
    };
    localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
  }

  let updatedCart = cartObj;

  if (cartObj.items?.length > 0) {
    let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails")) ? JSON.parse(localStorage.getItem("orderTimeDetails")) : { orderStartTime: "", orderEndTime: "", paymentStartTime: "" };
    orderTimeDetails = {
      ...orderTimeDetails,
      paymentStartTime: "",
      orderStartTime: orderTimeDetails.orderStartTime !== "" ? orderTimeDetails.orderStartTime : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), // Update orderStartTime to current time
    };
    localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
    updatedCart = addedToCart.isReturn ? cartObj : await pricingRuleController(addToCart, cartObj, cart, setCart, cartRef, orderType);
  }

  if (updatedCart?.couponInput?.length > 0) {
    await Promise.all(
      updatedCart.couponInput.map(async (coupon) => {
        updatedCart = await pricingRuleController(
          addToCart,
          updatedCart,
          cart,
          setCart,
          cartRef,
          orderType,
          true,
          coupon.couponCode,
          coupon.referenceId,
          coupon.mPricingCouponId,
          coupon.mPricingruleId
        );
      })
    );
  }

  if (cart.totalBillDicount) {
    const matchingPricingRules = await db.pricingRules.where("mPricingrulesId").equalsIgnoreCase(cart.totalBillDicount).toArray();
    processBillDiscounts(matchingPricingRules[0], cartObj, true);
    updatedCart = JSON.parse(localStorage.getItem("cartObj"));
  }

  if (cart.manualDiscountApplied && cart.manualDiscountApplied !== 0 && cart.discountType === "TD") {
    setLoader(true);
    setTimeout(() => {
      processTotalManualDiscount(cart.manualDiscountApplied);
      setLoader(false);
    }, 200);
  }
  let updatedTotalTax = 0;
  let updatedTotalPrice = 0;
  let updatedTotalItemsQty = 0;
  let updatedTotalDiscounts = 0;
  for (let i = 0; i < updatedCart?.items?.length; i += 1) {
    updatedTotalPrice += parseFloat((updatedCart.items[i].nettotal).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    updatedTotalItemsQty += updatedCart.items[i].weight;
    updatedTotalTax += parseFloat(updatedCart.items[i].taxAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
    updatedTotalDiscounts += updatedCart.items[i].discount ? parseFloat(updatedCart.items[i].discount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) : 0;
    updatedCart.items[i].discount = updatedCart.items[i].discount ? parseFloat(updatedCart.items[i].discount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) : 0;
    updatedCart.items[i].key = i;
  }

  const calculateRoundingDifference = (value) => {
    // Round up the payment to the nearest multiple of 0.25
    const roundedPayment = Math.ceil(value / 0.25) * 0.25;

    // Calculate the raw overpayment (before considering change)
    const rawOverpayment = roundedPayment - Math.abs(updatedTotalPrice);
    return parseFloat(rawOverpayment.toFixed(2));
  };
  index = updatedCart?.items.findIndex((p) => p.productId === addToCart.productId && p.upc === addToCart.upc);
  if (index !== -1) {
    // Remove the item from its current position
    const removedItem = updatedCart?.items.splice(index, 1)[0];
    
    // Place the item at the beginning of the array
    updatedCart?.items.unshift(removedItem);
  }
  const updatedTotalRoundOff = calculateRoundingDifference(updatedTotalPrice);
  let finalCartObj = {
    ...updatedCart,
    items: [...updatedCart?.items],
    total: parseFloat(updatedTotalPrice.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
    tax: parseFloat(updatedTotalTax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)),
    discount: updatedTotalDiscounts,
    totalQty: updatedTotalItemsQty,
    roundOff: updatedTotalRoundOff,
  };
  setCart(finalCartObj);
  localStorage.setItem("cartObj", JSON.stringify(finalCartObj));
  // quantityInputRef.current.focus();
  // quantityInputRef.current.select();
};
