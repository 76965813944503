import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, message, Input, Button } from "antd";
import { useHistory } from "react-router-dom";
// import { serverUrl } from "../../constants/config";
import { Link } from "react-router-dom";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
import { Scrollbars } from "react-custom-scrollbars";
import MenuIcon from "../../assets/images/icon.png";
import db from "../../database";
import data from "../../constants/opentillClosetill.json";
import "../style.css";
import {EditOutlined} from "@ant-design/icons";
import moment from "moment";

const CloseTillAll = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillSession = JSON.parse(localStorage.getItem("tillSession"));
  const [allExpectedAmount, setAllExpectedAmount] = useState(0);
  const [allTransactionCount, setAllTransactionCount] = useState(0);
  const [paymentsList, setPaymentsList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalOrdersCount, setTotalOrdersCount] = useState(0);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));

  const sessionId = tillSession.tillSessionId;
  const [totalOpeningAmount, setTotalOpeningAmount] = useState(0);
  const [salesAmount, setSalesAmount] = useState(0);
  const [cashSaleAmount, setCashSaleAmount] = useState(0);
  const [noteValue, setNoteValue] = useState("");
  const [editAmount,setEditAmount] = useState("");
  const [cashValues, setCashValues] = useState({ cashIn: 0, cashOut: 0, pettCashIn: 0, pettCashOut: 0 });

  useEffect(async () => {
    let obj = cashValues;
    let payments = []
    await db.tillEvents
      .where("tillSessionId")
      .equals(sessionId)
      .toArray()
      .then((tillEvent) => {
        if (tillEvent.length > 0 && tillEvent[0].cashInOutData) {
          setCashValues(tillEvent[0].cashInOutData);
          obj = tillEvent[0].cashInOutData;
        }
        if(tillEvent.length > 0){
          tillEvent[0].allPaymentsData.map((payment) => {
            if(payment.name.toLowerCase() !== "cash"){
              payment.amount = payment.amount >0 ? parseFloat(payment.amount.toFixed(2)) : parseFloat(payment.expectedAmount.toFixed(2));
              payment.actualAmount = payment.actualAmount >0 ? parseFloat(payment.actualAmount.toFixed(2)) : parseFloat(payment.expectedAmount.toFixed(2));
              payment.difference = 0;
            }
          })
          payments = tillEvent[0].allPaymentsData;
        }
      });

    if (posConfig.showDenominations === "N") {
      db.orders
        .where("tillSessionId")
        .equals(sessionId)
        .toArray()
        .then((orders) => {
          const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount;
          setTotalOpeningAmount(openAmount);
          setTotalOrdersCount(orders.length);
          const updatedTillSession = tillSession;
          const updatedAllPaymentsData = payments;
          const aPi = updatedAllPaymentsData?.findIndex((apd) => apd.name.toLowerCase() === "cash");
          updatedAllPaymentsData[aPi].amount = totalAmount;
          updatedAllPaymentsData[aPi].expectedAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount.toFixed(2));
          updatedAllPaymentsData[aPi].cashSaleAmount = parseFloat(updatedAllPaymentsData[aPi].expectedAmount.toFixed(2));
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].amount) - parseFloat(updatedAllPaymentsData[aPi].expectedAmount);
          updatedAllPaymentsData[aPi].difference = parseFloat(updatedAllPaymentsData[aPi].difference.toFixed(2));
          setCashSaleAmount(updatedAllPaymentsData[aPi].expectedAmount);
          updatedTillSession.closingTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
          updatedTillSession.closingCash = totalAmount;
          updatedTillSession.closingCashDenominations = [];
          updatedTillSession.allPaymentsData = updatedAllPaymentsData;
          updatedTillSession.totalOrdersCount = orders.length;
          db.tillEvents.where("tillSessionId").equals(updatedTillSession.tillSessionId).modify(updatedTillSession);
          localStorage.setItem("tillSession", JSON.stringify(updatedTillSession));
          setAllExpectedAmount(updatedAllPaymentsData[aPi].expectedAmount);
          setAllTransactionCount(updatedAllPaymentsData[aPi].transactionCount);
          setTotalAmount(updatedAllPaymentsData[aPi].amount);
          setTotalOrdersCount(orders.length);
          setPaymentsList([...payments]);
        });
    } else if (posConfig.showDenominations === "Y") {
      const tillSessionPayments = tillSession.allPaymentsData;
      let totalAmountAllPayments = 0;
      let transactionCount = 0;
      let total = 0;
      let salesAmount = 0;
      tillSessionPayments?.map((pay) => {
        totalAmountAllPayments += pay.expectedAmount;
        transactionCount += pay.transactionCount;
        if (pay.name.toLowerCase() === "cash") {
          salesAmount += pay?.cashSaleAmount - pay?.paymentReturn;
        } else {
          salesAmount += pay?.expectedAmount - pay?.paymentReturn;
        }
        pay.expectedAmount = pay.expectedAmount - pay.paymentReturn;
        return null;
      });
      
      const openAmount = JSON.parse(localStorage.getItem("tillSession"))?.totalOpeningAmount;
      setSalesAmount(salesAmount);

      payments.map((ele) => {
        if (ele.name.toLowerCase() === "cash") {
          ele.expectedAmount = ele.expectedAmount - ele.paymentReturn;
        }else {
          ele.expectedAmount = ele.expectedAmount - ele.paymentReturn;
          ele.amount = parseFloat((ele.amount - ele.paymentReturn).toFixed(2));
        }
        total += parseFloat(ele.amount);
      })
      totalAmountAllPayments = salesAmount + obj.cashIn - obj.cashOut + obj.pettCashIn - obj.pettCashOut + openAmount;
      tillSession.allPaymentsData = payments;
      setAllExpectedAmount(totalAmountAllPayments);
      setAllTransactionCount(transactionCount);
      setTotalAmount(total);
      setTotalOrdersCount(tillSession.totalOrdersCount);
      setPaymentsList([...payments]);
    }
  }, []);

  const history = useHistory();
  const currentDate = new Date().toLocaleDateString();
  const [displayClock, setDisplayClock] = useState("");
  useEffect(async () => {
    setDisplayClock(clock());
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  const confirmBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);

  const onFieldInput = (e) => {
    const { name, value } = e.target;
    const updatedPaymentsList = paymentsList;
    const paymentIndex = updatedPaymentsList.findIndex((pi) => pi.name === name);
    updatedPaymentsList[paymentIndex].amount = parseFloat(value);
    updatedPaymentsList[paymentIndex].actualAmount = parseFloat(value);
    const diff = parseFloat(value) - parseFloat(updatedPaymentsList[paymentIndex].expectedAmount);
    updatedPaymentsList[paymentIndex].difference = isNaN(diff) ? 0 : diff;
    setPaymentsList([...updatedPaymentsList]);
    let totalAmountVal = 0;

    updatedPaymentsList.map((p) => {
      return (totalAmountVal += isNaN(parseFloat(p.amount)) ? 0 : parseFloat(p.amount));
    });

    db.paymentsData
    .where("tillStatus")
    .equalsIgnoreCase("open")
    .modify((event) => {
      event.payments = updatedPaymentsList
    });
    db.tillEvents.where("tillSessionId").equals(sessionId).modify((event) => {
      event.allPaymentsData = updatedPaymentsList;
      event.allPaymentsData[paymentIndex].amount = parseFloat(value);
    })
    setTotalAmount(totalAmountVal);
  };

  const onFieldOut = (e) => {
    if (e.target.value === "") {
      const { name } = e.target;
      const value = 0;
      const updatedPaymentsList = paymentsList;
      const paymentIndex = updatedPaymentsList.findIndex((pi) => pi.name === name);
      updatedPaymentsList[paymentIndex].amount = value;
      const diff = parseFloat(value) - parseFloat(updatedPaymentsList[paymentIndex].expectedAmount);
      updatedPaymentsList[paymentIndex].difference = isNaN(diff) ? 0 : diff;
      setPaymentsList([...updatedPaymentsList]);
      let totalAmountVal = 0;
      updatedPaymentsList.map((p) => {
        return (totalAmountVal += isNaN(parseFloat(p.amount)) ? 0 : parseFloat(p.amount));
      });
      setTotalAmount(totalAmountVal);
    }
  };

  const checkFlag = () => {
    if (posConfig.cashDiffInShiftClose === "N") {
      const paymentMismatches = [];
      paymentsList.forEach((res) => {
        // Calculate the difference between expected amount and amount
        const integerPart = Math.floor(res.expectedAmount);
        const decimalPart = res.expectedAmount - integerPart;

        let roundedDecimal;
        if(res.name.toLowerCase() === "cash"){
          if (decimalPart <= 0.25 && decimalPart > 0) {
            roundedDecimal = 0.25;
          } else if (decimalPart <= 0.5 && decimalPart > 0) {
            roundedDecimal = 0.5;
          } else if (decimalPart <= 0.75 && decimalPart > 0) {
            roundedDecimal = 0.75;
          } else if (decimalPart > 0) {
            roundedDecimal = 1.0;
          } else {
            roundedDecimal = 0;
          }
        }


        const roundedValue = integerPart + roundedDecimal;
        // return roundedValue;

        const difference = res.name.toLowerCase() === "cash" ? roundedValue - res.amount : (res.expectedAmount).toFixed(2) - res.amount;

        // Check if the difference is not 0
        if (parseFloat(difference) !== 0) {
          // Store the payment method with its difference in the array
          paymentMismatches.push({
            paymentMethod: res.name,
            difference: difference,
          });
        }
      });

      // Check if there are any payment mismatches
      if (paymentMismatches.length > 0) {
        // Construct a single message for all payment mismatches
        const mismatchMessage = `Payment mismatches detected. Details: ${paymentMismatches.map(
          (mismatch) => `${mismatch.paymentMethod}: ${parseFloat(mismatch.difference).toFixed(2)}`
        )}`;
        // Display a single message for all mismatches
        message.info(mismatchMessage);
      } else {
        console.log("All payments match the expected amounts.");
        history.push("/cash-to-keep");
      }
    } else {
      history.push("/cash-to-keep");
    }
  };
  let reconstructedObject;

  const rawtillAccessMeta = tillData?.tillAccess?.tillAccessMeta;
  if (rawtillAccessMeta) {
    const rawDataArray = tillData?.tillAccess?.tillAccessMeta;

    // Find the object with the key "Open Till"
    const closeTillObject = rawDataArray.find((item) => item.key === "Close Till");

    // Access the value property of the found object
    const closeTillValue = closeTillObject?.value;

    if (closeTillValue !== undefined) {
      // The rest of your code that depends on closeTillValue
      // Clean up the string (remove extra characters)
      const cleanedData = closeTillValue.replace(/[{}"]/g, "");

      // Split the string into key-value pairs
      const keyValuePairs = cleanedData.split(",");

      // Create an object from key-value pairs
      reconstructedObject = {};
      keyValuePairs.forEach((pair) => {
        // Check if the pair contains a colon
        if (pair.includes(":")) {
          const [key, value] = pair.split(":");

          // Check if both key and value are not undefined before trimming
          if (key !== undefined && value !== undefined) {
            reconstructedObject[key.trim()] = value.trim();
          }
        }
      });
    } else {
      // Handle the case where closeTillValue is undefined
      console.error("closeTillValue is undefined");
    }
  }
  const spanValue =
  reconstructedObject === undefined
    ? 4 // Default value when reconstructedObject is undefined
    : posConfig.cashDiffInShiftClose === "Y" && reconstructedObject?.ExpectedAmount === "Y" && reconstructedObject?.Difference === "Y"
    ? 4 // All flags are "Y"
    : posConfig.cashDiffInShiftClose === "Y" && reconstructedObject?.ExpectedAmount === "Y" && reconstructedObject?.Difference === "N"
    ? 6 // cashDiffInShiftClose and ExpectedAmount are "Y", Difference is null
    : posConfig.cashDiffInShiftClose === "Y" && reconstructedObject?.ExpectedAmount === "N" && reconstructedObject?.Difference === "Y"
    ? 6 // cashDiffInShiftClose is "Y", ExpectedAmount is null, Difference is not null
    : posConfig.cashDiffInShiftClose === "Y" && reconstructedObject?.ExpectedAmount === "N" && reconstructedObject?.Difference === "N"
    ? 8 // cashDiffInShiftClose is "Y", ExpectedAmount and Difference are null
    : posConfig.cashDiffInShiftClose !== "Y" && reconstructedObject?.ExpectedAmount === "Y"
    ? 6 // cashDiffInShiftClose is not "Y", ExpectedAmount and Difference are not null
    : posConfig.cashDiffInShiftClose !== "Y" && reconstructedObject?.ExpectedAmount === "N"
    ? 8 // cashDiffInShiftClose is not "Y", ExpectedAmount is null, Difference is not null
    : 4;

  return (
    <div style={data.openTill.mainContainer}>
      <div style={data.openTill.mainContainer}>
        <Row style={{ height: "6vh", padding: "1% 0 0 1%" }}>
          <Col span={1} style={{ display: "flex", alignItems: "center", textAlign: "left" }}>
            <img src={MenuIcon} style={data.openTill.header.menuIcon} alt="" />
          </Col>
          <Col span={23}>
            <label
              style={{
                fontSize: "2vw",
                color: "#000000",
                letterSpacing: "0.36px",
                opacity: 1,
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Close Till
            </label>
          </Col>
        </Row>

        <div style={data.openTill.mainCardContainer}>
          <div style={{ padding: "1vw", display: "flex", paddingBottom: "0" }}>
            <div style={{ borderRadius: "3px", width: "75%" }}>
              <div key="z" style={data.openTill.mainCardFirst}>
                <Row style={{ padding: "1% 0%", borderBottom: "1px solid rgba(0, 0, 0, 0.09)" }}>
                  <Col span={spanValue} style={data.openTill.colAlign}>
                    <strong>Payment Name</strong>
                  </Col>
                  <Col span={spanValue} style={data.openTill.colAlign}>
                    <strong>Transactions</strong>
                  </Col>
                  <Col span={spanValue} style={{ ...data.openTill.alignCenter, display: reconstructedObject?.ExpectedAmount === "Y" ? "block" : "none" }}>
                    <strong>Expected Amount</strong>
                  </Col>
                  <Col span={spanValue} style={data.openTill.alignCenter}>
                    <strong>Actual Amount</strong>
                  </Col>
                  {posConfig.cashDiffInShiftClose === "Y" ? (
                    <Col span={spanValue} style={{ ...data.openTill.colAlign, display: reconstructedObject?.Difference === "Y" ? "block" : "none" }}>
                      <strong>Difference</strong>
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Scrollbars className="closeTillScroll">
                    {paymentsList.map((payment, index) => (
                      <Row style={{ marginBottom: "2%", marginTop: "1%" }}>
                        <Col span={spanValue} style={{...data.openTill.paymentColAlign,display:"flex",alignItems:"center",justifyContent:"center"}}>
                        {reconstructedObject?.CafeTips === "Y" && payment.name.toLowerCase() === "cafe tips" ? <EditOutlined style={{marginBottom:"1rem",position:"relative",left:"-1.5vw"}}/> : null}
                          <p style={data.openTill.paymentDetails}>{payment.name}</p>
                        </Col>
                        <Col span={spanValue} style={data.openTill.paymentColAlign}>
                          <p style={data.openTill.paymentDetails}>{payment.transactionCount}</p>
                        </Col>
                        <Col span={spanValue} style={data.openTill.colAlign}>
                          <Input
                            name={`${payment.name}`}
                            value={parseFloat(payment.expectedAmount) > 0 ? parseFloat(payment.expectedAmount).toFixed(2) : 0}
                            readOnly={true}
                            style={payment.name.toLowerCase() === "cash" && posConfig.showDenominations === "Y" ? data.openTill.currency : data.openTill.actualAmtInput}
                          />
                        </Col>
                        <Col span={spanValue} style={data.openTill.colAlign}>
                        {
                         reconstructedObject?.CafeTips === "Y" && payment.name.toLowerCase() === "cafe tips" && editAmount === "" ? 
                        <Button onClick={()=>{setEditAmount(payment.name)}} style={{height:"6vh",width:"7vw",borderRadius:"5px",fontSize:"2.2vh"}}>{payment.amount}</Button>
                        :
                        <Input
                        name={`${payment.name}`}
                        value={payment.amount}
                        type="number"
                        onChange={onFieldInput}
                        onBlur={onFieldOut}
                        readOnly={editAmount === payment.name ? false : true}
                        style={editAmount === payment.name ? {
                          "width": "68%",
                          "padding": "6px 1px",
                          "backgroundColor": "#F3F4F9",
                          "fontWeight": "400px",
                          "textAlign": "center",
                          "borderRadius": "5px",
                          "fontSize":"2.2vh",
                          "border" : "1px solid rgba(0, 0, 0, 0.85)"
                        } : data.openTill.currency}
                      />
                        }
                        </Col>
                        {posConfig.cashDiffInShiftClose === "Y" ? (
                          <Col span={spanValue} style={{ ...data.openTill.colAlign, display: reconstructedObject?.Difference === "Y" ? "block" : "none" }}>
                            <p style={data.openTill.paymentDetails}>{parseFloat(payment.difference).toFixed(2)}</p>
                          </Col>
                        ) : null}
                      </Row>
                    ))}
                  </Scrollbars>
                </Row>
              </div>
            </div>
            <div style={{ width: "25%", paddingLeft: "2.3%" }}>
              <div style={data.openTill.opentillRightCard}>
                <p style={data.openTill.emptyP} />
                <p style={data.openTill.emptyP}>Opened On</p>
                <div style={{ marginBottom: "0.5vh", marginTop: "-6px" }}>
                  <span style={data.openTill.currentDate}>{currentDate}</span>
                  <span style={data.openTill.displayClock}>{displayClock}</span>
                </div>
                <Form>
                  <p
                    style={{
                      marginBottom: "0.5vh",
                      fontSize: "2vh",
                      fontFamily: "Inter",
                      fontWeight: "600",
                      letterSpacing: "0px",
                      color: "#0F0718",
                    }}
                  >
                    Transactions
                  </p>
                  <Form.Item style={{ marginBottom: "0.8vh" }}>
                    <p style={data.openTill.transactionsAmtClose}>Payment Transactions</p>
                    <Input type="number" value={allTransactionCount} readOnly={true} className="transactionAmtInputClose" style={{ height: "4.5vh" }} />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh" }}>
                    <p style={data.openTill.transactionsAmtClose}>Retail Transactions</p>
                    <Input value={totalOrdersCount} readOnly={true} className="transactionAmtInputClose" style={{ height: "4.5vh" }} />
                  </Form.Item>
                  {/* my changes */}

                  <Form.Item style={{ marginBottom: "0.8vh", display: reconstructedObject ? (reconstructedObject.OpeningAmount === "Y" ? "block" : "none") : "block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Opening Amount</p>
                    <Input
                      value={`${parseFloat(JSON.parse(localStorage.getItem("tillSession")).totalOpeningAmount).toFixed(2)}`}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh"}}>
                    <p style={data.openTill.transactionsAmtClose}>Notes</p>
                    <Input
                      onChange={(e) => setNoteValue(e.target.value)}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh", display: reconstructedObject ? (reconstructedObject.SalesAmount === "Y" ? "block" : "none") : "block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Sales Amount</p>
                    <Input value={`${parseFloat(salesAmount).toFixed(2)}`} readOnly={true} className="transactionAmtInputClose" style={{ height: "4.5vh" }} />
                  </Form.Item>

                  <Form.Item style={{ marginBottom: "0.8vh", display: reconstructedObject ? (reconstructedObject.CashIn === "Y" ? "block" : "none") : "block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Cash In</p>
                    <Input value={`${parseFloat(cashValues.cashIn).toFixed(2)}`} readOnly={true} className="transactionAmtInputClose" style={{ height: "4.5vh" }} />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh", display: reconstructedObject ? (reconstructedObject.CashOut === "Y" ? "block" : "none") : "block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Cash Out</p>
                    <Input value={`${parseFloat(cashValues.cashOut).toFixed(2)}`} readOnly={true} className="transactionAmtInputClose" style={{ height: "4.5vh" }} />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh", display: reconstructedObject ? (reconstructedObject.PettyCashIn === "Y" ? "block" : "none") : "block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Petty Cash In</p>
                    <Input value={`${parseFloat(cashValues.pettCashIn).toFixed(2)}`} readOnly={true} className="transactionAmtInputClose" style={{ height: "4.5vh" }} />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh", display: reconstructedObject ? (reconstructedObject.pettyCashOut === "Y" ? "block" : "none") : "block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Petty Cash Out</p>
                    <Input value={`${parseFloat(cashValues.pettCashOut).toFixed(2)}`} readOnly={true} className="transactionAmtInputClose" style={{ height: "4.5vh" }} />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh", display: reconstructedObject ? (reconstructedObject.ExpectedAmount === "Y" ? "block" : "none") : "block" }}>
                    <p style={data.openTill.transactionsAmtClose}>Expected Amount</p>
                    <Input
                      value={`${parseFloat(allExpectedAmount) >= 0 ? parseFloat(allExpectedAmount).toFixed(2) : 0.0}`}
                      readOnly={true}
                      className="transactionAmtInputClose"
                      style={{ height: "4.5vh" }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: "0.8vh" }}>
                    <p style={data.openTill.transactionsAmtClose}>Total Amount</p>
                    <Input value={`${parseFloat(totalAmount).toFixed(2)}`} readOnly={true} className="transactionAmtInputClose" style={{ height: "4.5vh" }} />
                  </Form.Item>
                </Form>
                <Row style={{ paddingTop: "2vh" }}>
                  <Button ref={cancelBtnRef} style={data.openTill.btnCancel}>
                    <Link to={posConfig.showDenominations === "Y" ? "/close-till" : "/pos"}>Back</Link>
                  </Button>
                  <Button ref={confirmBtnRef} style={data.openTill.btnConfirm} onClick={checkFlag}>
                    Next
                  </Button>
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloseTillAll;
